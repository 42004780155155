import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/login",
    name: "login",
    meta: { 
      title: "BASF Mi Cuenta - Login",
    },
    component: () =>
      import(/* webpackChunkName: "login" */ "../views/Login.vue")
  },
  {
    path: "/",
    name: "landing",
    meta: { 
      title: "BASF Mi Cuenta",
      requiresAuth: true
    },
    component: () => import("../views/Landing-new.vue")
  },
  {
    path: "/landing/landing-new",
    name: "landing-new",
    meta: { 
      title: "BASF Mi Cuenta",
      requiresAuth: true
    },
    component: () => import("../views/Landing.vue")
  },
  {
    path: "/permisos",
    name: "permisos",
    meta: { 
      title: "BASF Mi Cuenta - Permisos",
      requiresAuth: true,
      hasAnyRole: ["Performance"]
    },
    component: () => import("../views/Permisos.vue")
  },
  {
    path: "/password/:url",
    name: "password",
    meta: { title: "BASF Mi Cuenta - Password" },
    component: () =>
      import(/* webpackChunkName: "password" */ "../views/Password.vue")
  },
  {
    path: "/excel",
    name: "excel",
    meta: { 
      title: "BASF Mi Cuenta - Excel",
      requiresAuth: true,
      hasAnyRole: ["ADMIN"]
    },
    component: () => import("../views/Excel.vue")
  },
  {
    path: "/metricas",
    name: "metricas",
    meta: { 
      title: "BASF Mi Cuenta - Métricas",
      requiresAuth: true
    },
    component: () => import("../views/Metricas.vue")
  },
  {
    path: "/seleccionarCuenta",
    name: "seleccionarCuenta",
    meta: { 
      title: "BASF Mi Cuenta - Seleccionar Cuenta",
      requiresAuth: true
    },
    component: () => import("../views/SeleccionarCuenta.vue")
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

router.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
  if (requiresAuth) {
    const user = Vue.prototype.$authService.getCurrentUser();
    if (!user || (user && to.meta.hasAnyRole && to.meta.hasAnyRole.indexOf(user.role) === -1)) {
      next('/login');
    } else {
      next();
    }
  } else {
    next();
  }
});

export default router;
