import * as jwt from 'jwt-decode';

class AuthService {
  constructor(axios) {
    this.axios = axios;
  }

  login(email, password) {
    return this.axios
      .post('/api/auth/login', {
        email,
        password
      })
      .then(response => {
        if (response.data) {
          localStorage.setItem('token', response.data);
          const user = this.getCurrentUser();
          localStorage.setItem("user-id", user.id);
        }
        return response.data;
      });
  }

  logout() {
    window.dispatchEvent(new CustomEvent('logout'));
  }

  getCurrentUser() {
    const token = localStorage.getItem('token');
    if(!token) {
      return null;
    }
    if (token && this.isTokenExpiring(token)) {
      return this.refreshToken().then(newToken => {
        localStorage.setItem('token', newToken);
        return jwt.jwtDecode(newToken);
      });
    }
    return jwt.jwtDecode(token);
  }

  isTokenExpiring(token) {
    const decoded = jwt.jwtDecode(token);
    const exp = decoded.exp * 1000; // JWT exp en segundos, por lo que multiplicamos por 1000
    return (exp - Date.now()) < (10 * 60 * 1000); // Considerar expiring si quedan menos de 10 minutos
  }

  refreshToken() {
    return this.axios.post('/api/auth/refresh-token')
      .then(response => response.data);
  }
}

export default AuthService;