<template>
  <transition name="fade-fast">
    <div class="snackbar" v-show="mostrarSnackbar == true">
      <p class="snackbar-texto">
        {{ texto }}
      </p>
    </div>
  </transition>
</template>

<script>
export default {
  components: {},
  props: {},
  data() {
    return {
      mostrarSnackbar: false,
      texto: "",
      myTimeout: null,
    };
  },
  created() {
    this.$eventHub.$on("snackbar", (texto) => this.abrirSnackbar(texto));
  },
  mounted() {},
  methods: {
    abrirSnackbar(texto) {
      clearTimeout(this.myTimeout);
      this.texto = texto;
      this.mostrarSnackbar = true;
      const that = this;
      this.myTimeout = setTimeout(function () {
        that.mostrarSnackbar = false;
      }, 2500);
    },
  },
};
</script>

<style>
.snackbar {
  position: absolute;
  bottom: 1.65vw;
  right: 1.5vw;
  background-color: #333;
  z-index: 1000000;
  width: 19.3vw;
  height: 4.35vw;
  border-radius: 0.5vw;
  box-shadow: 0 0.25vw 0.27vw 0 rgba(0, 0, 0, 0.21);
}
.snackbar-texto {
  font-family: Roboto, sans-serif;
  font-size: 1.1vw;
  color: #ffffff;
  margin: 0;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  text-align: center;
  padding-left: 0.5vw;
  padding-right: 0.5vw;
}
.snackbar-texto > img {
  position: relative;
  top: 0.6vw;
  width: 2.05vw;
  margin-left: 0.75vw;
}
/* PORTRAIT */
@media screen and (max-width: 500px) and (max-aspect-ratio: 13/9) {
  .snackbar {
    width: 50vw;
    height: 11vw;
    bottom: 5vw;
    right: 5vw;
    border-radius: 2vw;
  }
  .snackbar-texto {
    font-size: 3.4vw;
  }
}
</style>
