import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

const store = new Vuex.Store({
  state: {
    reemplazos: null,
    reemplazante: null
  },
  mutations: {
    // setters
    initialiseStore(state) {
      if (localStorage.getItem("store")) {
        // Replace the state object with the stored item
        this.replaceState(
          Object.assign(state, JSON.parse(localStorage.getItem("store")))
        );
      }
    },
    setReemplazos(state, reemplazos) {
      state.reemplazos = reemplazos;
    },
    setReemplazante(state, reemplazante) {
      state.reemplazante = reemplazante;
    },
  },
  actions: {
  },
  getters: {
    reemplazos(state) {
      return state.reemplazos;
    },
    reemplazante(state) {
      return state.reemplazante;
    },
  },
});

// Subscribe to store updates
store.subscribe((mutation, state) => {
  // Store the state object as a JSON string
  localStorage.setItem("store", JSON.stringify(state));
});

export default store;
