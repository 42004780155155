import Vue from "vue";
import axios from "axios";

class AxiosClient {

  createAxios() {
    // axios
    const apiClient = axios.create({
      baseURL: Vue.prototype.$localurl,
      withCredentials: true,
      timeout: 10000,
      headers: {
        'Content-Type': 'application/json',
      }
    });

    apiClient.interceptors.request.use(
      (config) => {
        const token = localStorage.getItem('token');
        if (token) {
          config.headers['Authorization'] = `Bearer ${token}`;
        }
        return config;
      },
      (error) => {
        return Promise.reject(error);
      }
    );

    apiClient.interceptors.response.use(
      (response) => response,
      (error) => {
        if (error.response && error.response.status === 401) {
          window.dispatchEvent(new CustomEvent('unauthorized'));
        }
        return Promise.reject(error);
      }
    );

    return apiClient;
  }
}

export default AxiosClient;