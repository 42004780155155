<template>
  <div id="app">
    <Snackbar></Snackbar>
    <CookiesPopup></CookiesPopup>
    <router-view />
    <v-idle v-show="false" @idle="logout" :loop="true" :duration="1800" />
  </div>
</template>

<script>
import Vue from "vue";
import Snackbar from "@/components/Snackbar.vue";
import CookiesPopup from "@/components/CookiesPopup.vue";
import 'material-symbols';
import Vidle from "v-idle";
Vue.use(Vidle);

import VueCookies from "vue-cookies";
Vue.use(VueCookies);

Vue.$cookies.config("1d");

export default {
  data() {
    return {};
  },
  components: { Snackbar, CookiesPopup },
  watch: {
    $route(to) {
      this.$eventHub.$emit("navbar-to", to.path);
      // this.getSelf();
    }
  },
  beforeCreate() {
    this.$store.commit("initialiseStore");
  },
  created() {
    this.$eventHub.$on("loged", () => this.getSelf());

    Vue.prototype.$usuario = {
      rol: {}
    };
  },
  mounted() {
    this.getSelf();
    console.log("13-11-2024");
  },
  methods: {
    async getSelf() {
      try {
        const response = await this.$axios.get(this.$localurl + "/api/usuario/self")
        if (response.data != "") {
          Vue.prototype.$usuario = response.data;
          localStorage.setItem("token-landing", this.$usuario.token);
          this.$forceUpdate();
          this.$eventHub.$emit("update", this.$usuario);
        }
      } catch (error) {
        console.log(error);
      }
    },
    logout() {
      this.$authService.logout();
    }
  }
};
</script>

<style src="@/assets/css/fonts.css"></style>
<style src="@/assets/css/transitions.css"></style>
<style src="@/assets/css/app.css"></style>
<style src="@/assets/css/error.css"></style>
