<template>
  <div v-if="showBar" class="cookies">
    <div class="cookies-bar">
      <img src="../assets/img/icons/cookie.svg" />
      <p>
        Si continúas navegando estás dando tu consentimiento a este sitio a
        utilizar <span @click="openPopup()">cookies.</span>
      </p>
      <img
        class="cookies-cerrar"
        src="../assets/img/icons/close_black.png"
        @click="acceptCookies()"
      />
    </div>
    <transition name="fade-fast">
      <div v-show="showPopup" class="app-popup-container">
        <div class="app-popup-mask" @click="close()"></div>
        <div class="cookies-popup">
          <div class="app-popup-row cookies-row">
            <label class="cookies-title">Cookies que utilizamos</label>
          </div>
          <div class="cookies-row">
            <div class="cookies-row-between">
              <p class="cookies-label-bold">
                De uso obligatorio
              </p>
              <p class="cookies-label-blue">
                Siempre activas
              </p>
            </div>
            <p class="cookies-text">
              Estas cookies son necesarias para que el sitio web funcione y no
              se pueden desactivar en nuestros sistemas. Por lo general, solo se
              configuran en respuesta a acciones realizadas por usted que
              equivalen a una solicitud de servicios, como configurar sus
              preferencias de privacidad, iniciar sesión o completar
              formularios. Puede configurar su navegador para que bloquee o le
              avise sobre estas cookies, pero algunas partes del sitio no
              funcionarán. Estas cookies no almacenan ningún dato de
              identificación personal
            </p>
          </div>
          <div class="cookies-footer">
            <button class="cookies-btn cookies-btn-cancelar" @click="close()">
              Volver
            </button>
            <button class="cookies-btn" @click="acceptCookies()">Listo</button>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  name: "cookiespopup",
  components: {},
  props: {},
  data() {
    return {
      showBar: false,
      showPopup: false
    };
  },
  created() {},
  mounted() {
    this.handleInitialRender();
    this.checkCookie();
  },
  computed: {},
   watch: {
    '$route'() {
      this.handleInitialRender();
    }
  },
  methods: {
    openPopup() {
      this.showPopup = true;
    },
    checkCookie() {
      const cookiesAccepted = this.getCookie("cookiesAccepted");
      if (cookiesAccepted === null || cookiesAccepted === "false") {
        this.showBar = true;
      }
    },
    handleInitialRender() {
      const cookiesAccepted = this.getCookie("cookiesAccepted");
      const pathname = window.location.href;
      console.log({ pathname, includesLogin: pathname.includes("login") })
      
      if (cookiesAccepted) return
      console.log("paso primer if")
      if (pathname.includes("login")) return
      
      this.openPopup();
    },
    setCookie(name, value, days) {
      let expires = "";
      if (days) {
        const date = new Date();
        date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
        expires = "; expires=" + date.toUTCString();
      }
      document.cookie = name + "=" + (value || "") + expires + "; path=/";
    },
    getCookie(name) {
      const nameEQ = name + "=";
      const ca = document.cookie.split(";");
      for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) === " ") c = c.substring(1, c.length);
        if (c.indexOf(nameEQ) === 0)
          return c.substring(nameEQ.length, c.length);
      }
      return null;
    },
    acceptCookies() {
      this.setCookie("cookiesAccepted", "true", 365);
      this.showPopup = false;
      this.showBar = false;
    },
    close() {
      this.showPopup = false;
    }
  }
};
</script>

<style scoped src="../assets/css/components/cookiespopup.css"></style>
